import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import computer from "../images/zany-jadraque-g2xKEEyd-_I-unsplash.jpg";
import NewsLetterInsert from "../components/newsletter";
import { Link, graphql } from "gatsby";
import ContentLayout from "../components/content-layout";

const Course = ({ title, url, price, image, children }) => {
  return (
    <div className="max-w-sm w-full lg:max-w-full lg:flex mb-3">
      <div
        className="h-48 lg:h-auto lg:w-48 flex-none bg-cover border-r md:border-r-none rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden border-l border-t border-b border-gray-400 bg-contain bg-no-repeat bg-center bg-gray-200"
        style={{ backgroundImage: `url(${image})` }}
        title={title}
      ></div>
      <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
        <div className="mb-4">
          <div className="text-gray-900 font-bold text-xl mb-2">
            <p to={url} className="text-2xl text-red-700">
              {title}
            </p>
          </div>
          <p
            className="text-gray-700 text-base"
            dangerouslySetInnerHTML={{ __html: children }}
          ></p>
        </div>
        <div className="flex justify-end">
          {price ? (
            <p className="text-2xl font-bold">{price}</p>
          ) : (
            <p className="text-2xl font-bold text-red-700">Coming Soon</p>
          )}
        </div>
      </div>
    </div>
  );
};

const Courses = ({ data }) => {
  const courses = data.allMarkdownRemark.edges;
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Courses" />
      <ContentLayout title={`Take Our Courses`} image={computer} color="md:text-white">
        <div className="w-full flex flex-wrap mt-10 md:mt-28">
          <div className="w-full md:grid md:grid-cols-5 md:gap-4">
            <div className="md:col-span-3">
              {courses.map(({ node }) => (
                <Course
                  title={node.frontmatter.title}
                  url={node.frontmatter.path}
                  key={node.frontmatter.path}
                  price={node.frontmatter.price}
                  image={node.frontmatter.image}
                >
                  {node.excerpt}
                </Course>
              ))}
            </div>
            <div className="md:col-span-2 content-end">
              <NewsLetterInsert />
            </div>
          </div>
        </div>
      </ContentLayout>
    </Layout>
  );
};

export const query = graphql`
  query CoursesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/courses/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            price
            image
          }
          excerpt(format: HTML)
        }
      }
    }
  }
`;

export default Courses;
